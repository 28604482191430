import { Link } from "react-router-dom";
import useStore from "../store/store";
import Session from "../store/session";

const Navbar = () => {
	const { user, setUser } = useStore((state) => state);

	const handleLogout = () => {
		Session.logout();
		setUser(null);
		window.location.href = "/login";
	};

	return (
		<nav className="navbar navbar-expand-lg navbar-dark bg-primary">
			<div className="container">
				<Link className="navbar-brand" to="/">
					API Monitoring Service
				</Link>
				<button
					className="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarNav"
					aria-controls="navbarNav"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarNav">
					<ul className="navbar-nav ml-auto">
						{user && (
							<>
								<li className="nav-item">
									<Link className="nav-link" to="/admin">
										Admin
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/">
										Dashboard
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link"
										to="/api-endpoints"
									>
										API Endpoints
									</Link>
								</li>
								{/* <li className="nav-item">
									<Link className="nav-link" to="/websockets">
										WebSockets
									</Link>
								</li> */}
								<li className="nav-item">
									<Link
										className="nav-link"
										to="/alerts-history"
									>
										Alerts
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link"
										to="/testing-history"
									>
										Testing History
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/groups">
										Groups
									</Link>
								</li>
								<li className="nav-item">
									<button
										className="nav-link btn btn-link"
										onClick={handleLogout}
									>
										Logout
									</button>
								</li>
							</>
						)}
						{!user && (
							<li className="nav-item">
								<Link className="nav-link" to="/login">
									Login
								</Link>
							</li>
						)}
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
