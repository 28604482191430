import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Container,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from "@mui/material";
import {
	CheckOutlined,
	CloseOutlined,
	QuestionMarkOutlined,
} from "@mui/icons-material";
import axios from "axios";
import useStore from "../store/store";
import { CustomTextField, CustomButton, CustomTypography } from "../controls";
import {
	TestingResultsDialog,
	BearerDialog,
	ThresholdDialog,
} from "../components";

const AddNewEndpoint = () => {
	const navigate = useNavigate();
	const {
		setIsLoading,
		toEdit,
		setToEdit,
		setSuccessMessage,
		setInfoMessage,
		setErrorMessage,
		user,
	} = useStore((state) => state);
	const [isTestOpen, setIsTestOpen] = useState(false);
	const [isBearerOpen, setIsBearerOpen] = useState(false);
	const [isThresholdOpen, setIsThreshold] = useState(false);
	const [testData, setTestData] = useState([]);
	const [groups, setGroups] = useState([]);
	const [newEndpoint, setNewEndpoint] = useState(
		toEdit
			? toEdit
			: {
					_id: null,
					nickname: "",
					groupName: "",
					url: "",
					method: "GET",
					pathParameters: "",
					queryParameters: "",
					authentication: "",
					authenticationType: "None",
					bearerToken: "",
					reqBody: "",
					dataPoints: "",
					testFrequency: "",
					thresholdType: {},
					warningThreshold: "",
					alertThreshold: "",
					isActive: true,
			  }
	);

	useEffect(() => {
		if (!user.isAdmin) navigate("/api-endpoints");
		fetchGroups();
	}, []);

	const fetchGroups = async () => {
		setIsLoading(true);
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/groups?page=${1}&limit=${-1}`
			);
			setGroups(res.data.data);
			setIsLoading(false);
		} catch (err) {
			console.error("Error fetching groups:", err);
			setIsLoading(false);
		}
	};

	const handleInputChange = (e) => {
		setNewEndpoint({ ...newEndpoint, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		if (newEndpoint._id === null) {
			try {
				let res = await axios.post(
					`${process.env.REACT_APP_API_URL}/api-endpoints`,
					newEndpoint
				);
				if (res.status === 200) {
					handleResetEndpoint();
					setIsLoading(false);
					setSuccessMessage(
						"API Endpoint has been saved successfully"
					);
					navigate("/api-endpoints");
				}
			} catch (err) {
				setErrorMessage("Error creating endpoint. Please try again.");
				console.error("Error creating API endpoint:", err);
				setErrorMessage(err);
				setIsLoading(false);
			}
		} else {
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/api-endpoints/${newEndpoint._id}`,
					newEndpoint
				);
				if (res.status === 200) {
					handleResetEndpoint();
					setIsLoading(false);
					setToEdit(null);
					setInfoMessage("API Endpoint updated successfully");
					navigate("/api-endpoints");
				}
			} catch (err) {
				console.error("Error updating API endpoint:", err);
				setErrorMessage("Error updating endpoint. Please try again.");
				setIsLoading(false);
			}
		}
	};

	const handleResetEndpoint = () => {
		setNewEndpoint({
			_id: null,
			nickname: "",
			groupName: "",
			url: "",
			pathParameters: "",
			queryParameters: "",
			dataPoints: "",
			authentication: newEndpoint.bearerToken
				? `Bearer ${newEndpoint.bearerToken}`
				: "",
			authenticationType: newEndpoint.bearerToken ? "Bearer" : "None",
			bearerToken: newEndpoint.bearerToken || "",
			testFrequency: "",
			warningThreshold: "",
			alertThreshold: "",
			method: "GET",
			reqBody: "",
			isActive: true,
		});
	};

	const handleTestEndpoint = async (id) => {
		setIsLoading(true);
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/api-endpoints/${id}/test`,
				newEndpoint
			);

			setTestData(res.data);
			setIsTestOpen(true);
			setIsLoading(false);
		} catch (err) {
			console.error("Error testing API endpoint:", err);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (newEndpoint.authenticationType === "Bearer") setIsBearerOpen(true);
	}, [newEndpoint.authenticationType]);

	return (
		<>
			<Container>
				<CustomTypography
					text={
						toEdit ? "Update API Endpoint" : "Add New API Endpoint"
					}
				/>
				<form className="mt-3">
					<div className="row">
						<div className="col-4">
							<CustomTextField
								label="Nickname"
								name="nickname"
								value={newEndpoint.nickname}
								onChange={handleInputChange}
								placeholder="Nickname"
								required
							/>
						</div>
						<div className="col-4">
							<FormControl sx={{ my: 1 }} fullWidth size="small">
								<InputLabel id="select-groupName-label">
									Group Name
								</InputLabel>
								<Select
									labelId="select-groupName-label"
									id="select-groupName"
									name="groupName"
									label="Group Name"
									value={newEndpoint.groupName}
									onChange={handleInputChange}
								>
									{groups.map((group, idx) => {
										return (
											<MenuItem
												key={idx}
												value={group.name}
											>
												{group.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
						<div className="col-4">
							<FormControl sx={{ my: 1 }} fullWidth size="small">
								<InputLabel id="select-method-label">
									HTTP Method
								</InputLabel>
								<Select
									labelId="select-method-label"
									id="select-method"
									name="method"
									label="HTTP Method"
									value={newEndpoint.method}
									onChange={handleInputChange}
								>
									<MenuItem value="GET">GET</MenuItem>
									<MenuItem value="POST">POST</MenuItem>
									<MenuItem value="PUT">PUT</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<CustomTextField
						label="URL"
						name="url"
						type="url"
						value={newEndpoint.url}
						onChange={handleInputChange}
						placeholder="URL"
						required
					/>
					<CustomTextField
						label="Path"
						name="pathParameters"
						value={newEndpoint.pathParameters}
						onChange={handleInputChange}
						placeholder="Path parameters example: /v1/agency/{path-parameter}"
					/>
					<CustomTextField
						label="Query Parameters"
						name="queryParameters"
						value={newEndpoint.queryParameters}
						onChange={handleInputChange}
						placeholder="Query parameters example: /v1/agency/{path-parameter}?{queryParameter=ProQA} - write in exact same manner like 'username=ProQA'"
					/>
					<div className="row">
						<div className="col-8">
							<CustomTextField
								label="Authentication"
								name="authentication"
								value={newEndpoint.authentication}
								onChange={handleInputChange}
								placeholder="Add authentication parameters as comma separated, with key value indicated by an =. Example: username=abc, password=xyz"
								slotProps={{
									input: {
										readOnly:
											newEndpoint.authenticationType ===
											"Bearer"
												? true
												: false,
									},
								}}
								helperText={
									newEndpoint.authenticationType === "Basic"
										? "Important Note: For basic auth, the input must be in this format: username=your-username, password=your-password."
										: newEndpoint.authenticationType ===
										  "Bearer"
										? "This field is read-only & will be autofilled once you receive the token. Do not try to write in this field."
										: ""
								}
							/>
						</div>
						<div className="col-4">
							<FormControl sx={{ my: 1 }} fullWidth size="small">
								<InputLabel id="select-token-label">
									Select Auth Type
								</InputLabel>
								<Select
									labelId="select-token-label"
									id="select-token"
									name="authenticationType"
									label="Select Auth Type"
									value={newEndpoint.authenticationType}
									onChange={handleInputChange}
								>
									<MenuItem value="None">None</MenuItem>
									<MenuItem value="Basic">Basic</MenuItem>
									<MenuItem value="Bearer">Bearer</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
					<CustomTextField
						label="Request Body"
						name="reqBody"
						multiline
						rows={4}
						value={newEndpoint.reqBody}
						onChange={handleInputChange}
						placeholder="Enter request body as JSON"
					/>
					<div className="row">
						<div className="col-3">
							<CustomTextField
								label="Test Frequency (min)"
								name="testFrequency"
								type="number"
								value={newEndpoint.testFrequency}
								onChange={handleInputChange}
								placeholder="Test Frequency (min)"
								required
							/>
						</div>
						<div className="col-3 d-flex">
							<CustomButton
								variant="text"
								color="dark"
								text="Select Threshold Type"
								onClick={() => setIsThreshold(true)}
								my={1}
								fullWidth={true}
								className="border"
							/>
						</div>
						<div className="col-3">
							<CustomTextField
								label="Warning Threshold (ms)"
								name="warningThreshold"
								type="number"
								value={newEndpoint.warningThreshold}
								onChange={handleInputChange}
								placeholder="Warning Threshold (ms)"
								required
							/>
						</div>
						<div className="col-3">
							<CustomTextField
								label="Alert Threshold (ms)"
								name="alertThreshold"
								type="number"
								value={newEndpoint.alertThreshold}
								onChange={handleInputChange}
								placeholder="Alert Threshold (ms)"
								required
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<CustomButton
								text={
									newEndpoint._id === null
										? "Add API Endpoint"
										: "Update API Endpoint"
								}
								size="normal"
								onClick={handleSubmit}
								my={1}
								mx="0px"
								fullWidth
								icon={<CheckOutlined />}
							/>
						</div>
						<div className="col-4">
							<CustomButton
								text={
									newEndpoint._id === null
										? "Test before Save"
										: "Test before Update"
								}
								size="normal"
								onClick={() => handleTestEndpoint(null)}
								my={1}
								mx="0px"
								fullWidth
								icon={<QuestionMarkOutlined />}
							/>
						</div>
						<div className="col-4">
							<CustomButton
								text="Cancel"
								size="normal"
								onClick={() => {
									handleResetEndpoint();
									setToEdit(null);
									navigate("/api-endpoints");
								}}
								my={1}
								mx="0px"
								fullWidth
								icon={<CloseOutlined />}
							/>
						</div>
					</div>
				</form>
			</Container>

			<TestingResultsDialog
				isTestOpen={isTestOpen}
				title="API Test Results"
				testData={testData}
				handleClose={() => setIsTestOpen(false)}
			/>

			<BearerDialog
				isBearerOpen={isBearerOpen}
				handleClose={() => setIsBearerOpen(false)}
				newEndpoint={newEndpoint}
				setNewEndpoint={setNewEndpoint}
			/>

			<ThresholdDialog
				toEdit={toEdit && toEdit.thresholdType}
				isThresholdOpen={isThresholdOpen}
				handleClose={() => setIsThreshold(false)}
				newEndpoint={newEndpoint}
				setNewEndpoint={setNewEndpoint}
			/>
		</>
	);
};

export default AddNewEndpoint;
