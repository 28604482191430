import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, TextField, Button } from "@mui/material";
import axios from "axios";
import useStore from "../store/store";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();
	const {
		setIsLoading,
		setUser,
		setToken,
		setSuccessMessage,
		setErrorMessage,
	} = useStore((state) => state);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/users/login`,
				{
					email,
					password,
				}
			);
			const { token } = res.data;
			setToken(token);

			const decoded = JSON.parse(atob(token.split(".")[1]));
			const newUser = decoded.user;
			setUser(newUser);
			setIsLoading(false);
			setSuccessMessage("Login successful");
			navigate("/admin");
		} catch (err) {
			console.error("Error logging in:", err);
			setErrorMessage(
				"An error occured while logging in. Please try again"
			);
			setIsLoading(false);
		}
	};

	return (
		<Container maxWidth="sm">
			<Typography variant="h4" gutterBottom>
				Login
			</Typography>
			<form onSubmit={handleSubmit}>
				<TextField
					label="Email"
					type="email"
					fullWidth
					margin="normal"
					variant="outlined"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
				/>
				<TextField
					label="Password"
					type="password"
					fullWidth
					margin="normal"
					variant="outlined"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					required
				/>
				<Button
					type="submit"
					variant="contained"
					color="primary"
					fullWidth
					style={{ marginTop: "16px" }}
				>
					Login
				</Button>
			</form>
		</Container>
	);
};

export default Login;
