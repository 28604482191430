import { useState } from "react";
import {
	Container,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@mui/material";
import {
	ResponseTimeBarChart,
	StatusCodeBarChart,
	StatusCodesPieChart,
	IsActivePieChart,
} from "./charts/";

const Chart = ({ data }) => {
	const [graphType, setGraphType] = useState("response-time");

	return (
		<>
			{data && data.length > 0 && (
				<div className="row d-flex justify-content-end my-3 mr-3">
					<FormControl sx={{ my: 1, maxWidth: 200 }} size="small">
						<InputLabel id="select-graph-label">
							Graph Type
						</InputLabel>
						<Select
							labelId="select-graph-label"
							id="select-graph"
							name="graphType"
							label="Graph Type"
							value={graphType}
							onChange={(e) => setGraphType(e.target.value)}
						>
							<MenuItem value="response-time">
								Response Time
							</MenuItem>
							<MenuItem value="isActive">Active Status</MenuItem>
							<MenuItem value="status-codes">
								Status Codes
							</MenuItem>
							<MenuItem value="status-code">Status Code</MenuItem>
						</Select>
					</FormControl>

					{graphType === "response-time" && (
						<Container
							className="page mb-4 d-flex justify-content-center"
							maxWidth="lg"
						>
							<ResponseTimeBarChart endpoints={data} />
						</Container>
					)}

					{graphType === "status-codes" && (
						<Container
							className="page mb-4 d-flex justify-content-center"
							maxWidth="lg"
						>
							<StatusCodesPieChart endpoints={data} />
						</Container>
					)}

					{graphType === "isActive" && (
						<Container
							className="page mb-4 d-flex justify-content-center"
							maxWidth="lg"
						>
							<IsActivePieChart endpoints={data} />
						</Container>
					)}

					{graphType === "status-code" && (
						<Container
							className="page mb-4 d-flex justify-content-center"
							maxWidth="lg"
						>
							<StatusCodeBarChart endpoints={data} />
						</Container>
					)}
				</div>
			)}
		</>
	);
};

export default Chart;
