import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Container,
	TextField,
	Button,
	Checkbox,
	FormControlLabel,
} from "@mui/material";
import { CheckOutlined, CloseOutlined } from "@mui/icons-material";
import axios from "axios";
import useStore from "../store/store";
import { CustomButton, CustomTypography } from "../controls";

const CreateUser = () => {
	const {
		setIsLoading,
		setSuccessMessage,
		setErrorMessage,
		user,
		toEdit,
		setToEdit,
	} = useStore((state) => state);

	const [email, setEmail] = useState(toEdit ? toEdit.email : "");
	const [password, setPassword] = useState(toEdit ? toEdit.password : "");
	const [checked, setChecked] = useState(toEdit ? toEdit.isAdmin : false);
	const navigate = useNavigate();

	useEffect(() => {
		if (!user.isAdmin) navigate("/admin");
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			if (toEdit === null) {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/users/create`,
					{
						email,
						password,
						isAdmin: checked ? checked : false,
					}
				);

				if (res.status === 200) {
					setSuccessMessage("User created successfully");
					handleResetForm();
					setIsLoading(false);
					setToEdit(null);
					navigate("/admin");
				}
			} else {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/users/${toEdit._id}`,
					{
						email,
						isAdmin: checked ? checked : false,
					}
				);

				if (res.status === 200) {
					setSuccessMessage("User updated successfully");
					handleResetForm();
					setIsLoading(false);
					setToEdit(null);
					navigate("/admin");
				}
			}
		} catch (err) {
			console.error("Error:", err);
			setErrorMessage("Something went wrong. Please try again");
			setIsLoading(false);
		}
	};

	const handleResetForm = () => {
		setEmail("");
		setPassword("");
		setChecked(false);
	};

	return (
		<Container maxWidth="sm">
			<CustomTypography text="Create User" />

			<form onSubmit={handleSubmit}>
				<TextField
					label="Email"
					type="email"
					fullWidth
					margin="normal"
					variant="outlined"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
				/>
				{!toEdit && (
					<TextField
						label="Password"
						type="password"
						fullWidth
						margin="normal"
						variant="outlined"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
					/>
				)}
				<FormControlLabel
					label="This user is an admin"
					control={
						<Checkbox
							checked={checked}
							onChange={(e) => setChecked(e.target.checked)}
							inputProps={{ "aria-label": "controlled" }}
						/>
					}
				/>
				<CustomButton
					text={toEdit ? "Update User" : "Create User"}
					variant="contained"
					fullWidth
					icon={<CheckOutlined />}
					onClick={handleSubmit}
					my={1}
				/>

				<CustomButton
					text="Cancel"
					variant="outlined"
					fullWidth
					onClick={() => {
						setToEdit(null);
						navigate("/admin");
					}}
					icon={<CloseOutlined />}
					my={1}
				/>
			</form>
		</Container>
	);
};

export default CreateUser;
