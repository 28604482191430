import { useEffect, useState } from "react";
import {
	Container,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Box,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import axios from "axios";
import useStore from "../store/store";
import { CustomTypography, CustomChip, MethodBox } from "../controls";

const TestDetails = () => {
	const [data, setData] = useState(null);
	const { setIsLoading } = useStore((state) => state);

	const fetchData = async () => {
		setIsLoading(true);
		let id = window.location.href.split("id=").pop();
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/testing-history/${id}`
			);
			setData(res.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<Container>
			<CustomTypography text="Test Details" />

			{data ? (
				<Grid container spacing={3} sx={{ mb: 3 }}>
					<Grid size={{ xs: 12, md: 6 }}>
						<TableContainer
							component={Paper}
							className="mt-2 max-w-[200px]"
						>
							<Table className="table-sm">
								<TableHead>
									<TableRow>
										<TableCell colSpan={2}>
											Request Details
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.apiMsg && (
										<TableRow>
											<TableCell>
												API Response Message
											</TableCell>
											<TableCell>{data.apiMsg}</TableCell>
										</TableRow>
									)}
									{data.msg && (
										<TableRow>
											<TableCell>
												Server Message
											</TableCell>
											<TableCell>{data.msg}</TableCell>
										</TableRow>
									)}
									{data.status && (
										<TableRow>
											<TableCell>Status</TableCell>
											<TableCell>
												{data.status === 200 ? (
													<CustomChip
														label={data.status}
														color="success"
													/>
												) : data.status === 500 ? (
													<CustomChip
														label={data.status}
														color="error"
													/>
												) : data.status === 0 ? (
													"N/A"
												) : (
													<CustomChip
														label={data.status}
														color="warning"
													/>
												)}
											</TableCell>
										</TableRow>
									)}
									{data.status && (
										<TableRow>
											<TableCell>Is Active?</TableCell>
											<TableCell>
												{data.status === 200 ? (
													<span
														style={{
															color: "green",
														}}
													>
														Active
													</span>
												) : (
													<span
														style={{ color: "red" }}
													>
														Inactive
													</span>
												)}
											</TableCell>
										</TableRow>
									)}
									{data.responseTime && (
										<TableRow>
											<TableCell>
												API Response Time
											</TableCell>
											<TableCell>
												{data.responseTime} ms
											</TableCell>
										</TableRow>
									)}
									{data.data && (
										<TableRow>
											<TableCell>Response Data</TableCell>
											<TableCell
												sx={{
													whiteSpace: "normal",
													wordBreak: "break-word",
												}}
											>
												<Box
													sx={{
														backgroundColor:
															"#f5f5f5",
														boxShadow:
															"2px 2px 2px rgba(0, 0, 0, 0.1)",
													}}
												>
													<SyntaxHighlighter
														language="json"
														style={materialLight}
													>
														{typeof data.data ===
														"string"
															? JSON.stringify(
																	JSON.parse(
																		data.data
																	),
																	null,
																	2
															  )
															: JSON.stringify(
																	data.data,
																	null,
																	2
															  )}
													</SyntaxHighlighter>
												</Box>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid size={{ xs: 12, md: 6 }}>
						<TableContainer
							component={Paper}
							className="mt-2 max-w-[200px]"
						>
							<Table className="table-sm">
								<TableHead>
									<TableRow>
										<TableCell colSpan={2}>
											API Endpoint Details
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.endpoint.method && (
										<TableRow>
											<TableCell>HTTP Method</TableCell>
											<TableCell>
												{data.endpoint.method ===
													"GET" && (
													<MethodBox
														label={
															data.endpoint.method
														}
														color="#4CAF50"
													/>
												)}
												{data.endpoint.method ===
													"POST" && (
													<MethodBox
														label={
															data.endpoint.method
														}
														color="#FFC107"
													/>
												)}
												{data.endpoint.method ===
													"PUT" && (
													<MethodBox
														label={
															data.endpoint.method
														}
														color="#1976D2"
													/>
												)}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.createdAt && (
										<TableRow>
											<TableCell>Date & Time</TableCell>
											<TableCell>
												{data.endpoint.createdAt}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.msg && (
										<TableRow>
											<TableCell>Updated At</TableCell>
											<TableCell>
												{data.endpoint.updatedAt}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.isActive && (
										<TableRow>
											<TableCell>Is Active?</TableCell>
											<TableCell>
												{data.endpoint.isActive ? (
													<span
														style={{
															color: "green",
														}}
													>
														Active
													</span>
												) : (
													<span
														style={{ color: "red" }}
													>
														Inactive
													</span>
												)}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.nickname && (
										<TableRow>
											<TableCell>Nick Name</TableCell>
											<TableCell>
												{data.endpoint.nickname}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.groupName && (
										<TableRow>
											<TableCell>Group Name</TableCell>
											<TableCell>
												{data.endpoint.groupName}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.url && (
										<TableRow>
											<TableCell>URL</TableCell>
											<TableCell>
												{data.endpoint.url}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.pathParameters && (
										<TableRow>
											<TableCell>
												Path Parameters
											</TableCell>
											<TableCell>
												{data.endpoint.pathParameters}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.queryParameters && (
										<TableRow>
											<TableCell>
												Query Parameters
											</TableCell>
											<TableCell>
												{data.endpoint.queryParameters}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.authentication && (
										<TableRow>
											<TableCell>
												Authentication
											</TableCell>
											<TableCell>
												{data.endpoint.authentication}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.authenticationType && (
										<TableRow>
											<TableCell>
												Authentication Type
											</TableCell>
											<TableCell>
												{
													data.endpoint
														.authenticationType
												}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.reqBody && (
										<TableRow>
											<TableCell>Request Body</TableCell>
											<TableCell
												sx={{
													whiteSpace: "normal",
													wordBreak: "break-word",
												}}
											>
												<Box
													sx={{
														backgroundColor:
															"#f5f5f5",
														boxShadow:
															"2px 2px 2px rgba(0, 0, 0, 0.1)",
													}}
												>
													<SyntaxHighlighter
														language="json"
														style={materialLight}
													>
														{typeof data.endpoint
															.reqBody ===
														"string"
															? JSON.stringify(
																	JSON.parse(
																		data
																			.endpoint
																			.reqBody
																	),
																	null,
																	2
															  )
															: JSON.stringify(
																	data
																		.endpoint
																		.reqBody,
																	null,
																	2
															  )}
													</SyntaxHighlighter>
												</Box>
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.dataPoints && (
										<TableRow>
											<TableCell>Request Body</TableCell>
											<TableCell>
												{data.endpoint.dataPoints}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.lastResponseTime && (
										<TableRow>
											<TableCell>
												Last Response Time
											</TableCell>
											<TableCell>
												{data.endpoint.lastResponseTime}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.testFrequency && (
										<TableRow>
											<TableCell>
												Test Frequency
											</TableCell>
											<TableCell>
												{data.endpoint.testFrequency}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.thresholdType && (
										<TableRow>
											<TableCell>
												Threshold Type
											</TableCell>
											<TableCell
												sx={{
													whiteSpace: "normal",
													wordBreak: "break-word",
												}}
											>
												<Box
													sx={{
														backgroundColor:
															"#f5f5f5",
														boxShadow:
															"2px 2px 2px rgba(0, 0, 0, 0.1)",
													}}
												>
													<SyntaxHighlighter
														language="json"
														style={materialLight}
													>
														{typeof data.endpoint
															.thresholdType ===
														"string"
															? JSON.stringify(
																	JSON.parse(
																		data
																			.endpoint
																			.thresholdType
																	),
																	null,
																	2
															  )
															: JSON.stringify(
																	data
																		.endpoint
																		.thresholdType,
																	null,
																	2
															  )}
													</SyntaxHighlighter>
												</Box>
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.warningThreshold && (
										<TableRow>
											<TableCell>
												Warning Threshold
											</TableCell>
											<TableCell>
												{data.endpoint.warningThreshold}
											</TableCell>
										</TableRow>
									)}
									{data.endpoint.alertThreshold && (
										<TableRow>
											<TableCell>
												Alert Threshold
											</TableCell>
											<TableCell>
												{data.endpoint.alertThreshold}
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			) : (
				<span>
					<i>No data found</i>
				</span>
			)}
		</Container>
	);
};

export default TestDetails;
