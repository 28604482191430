import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Switch,
	FormControlLabel,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	Container,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import axios from "axios";
import useStore from "../store/store";
import { CustomTypography, CustomButton, CustomTextField } from "../controls";

const AlertsHistory = () => {
	const navigate = useNavigate();
	const [logs, setLogs] = useState([]);
	const [query, setQuery] = useState("");
	const [isWarning, setIsWarning] = useState(false);
	const [isAlert, setIsAlert] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const { setIsLoading, user } = useStore((state) => state);
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);

	useEffect(() => {
		fetchLogs();
	}, []);

	const fetchLogs = async () => {
		setIsLoading(true);
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/alerts-history?page=${page}&limit=${limit}`
			);
			if (res.status === 200) {
				setLogs(res.data.data);
				setCount(res.data.total);
				setIsLoading(false);
			}
		} catch (err) {
			console.error("Error fetching API logs:", err);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		let filteredLogs = logs;

		filteredLogs = filteredLogs.filter(
			(log) =>
				log.endpoint.groupName
					.toLowerCase()
					.includes(query.toLowerCase()) ||
				log.endpoint.nickname
					.toLowerCase()
					.includes(query.toLowerCase())
		);

		if (isWarning && isAlert) {
			setFilteredData(filteredLogs);
			return;
		}

		if (isWarning) {
			filteredLogs = filteredLogs.filter((log) =>
				log.title.toLowerCase().includes("warning")
			);
		}
		if (isAlert) {
			filteredLogs = filteredLogs.filter((log) =>
				log.title.toLowerCase().includes("alert")
			);
		}

		setFilteredData(filteredLogs);
	}, [isWarning, isAlert, query, logs]);

	useEffect(() => {
		fetchLogs(page, limit);
	}, [page, limit]);

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	const handleLimitChange = (event) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Container>
			<CustomTypography text="Alerts History" />

			{user.isAdmin && (
				<div style={{ textAlign: "right" }}>
					<CustomButton
						onClick={() => navigate("/alerts")}
						text="Add New Alert"
						mx="0px"
						icon={<AddOutlined />}
					/>
				</div>
			)}

			<div className="row mt-3">
				<div className="col-8">
					<CustomTextField
						label={`Filter by nick name or group name`}
						value={query}
						onChange={(e) => setQuery(e.target.value)}
						required={false}
					/>
				</div>
				<div className="col-2">
					<FormControlLabel
						control={
							<Switch
								checked={isWarning}
								onChange={(e) => setIsWarning(e.target.checked)}
							/>
						}
						label="Show Warning Thresholds"
						style={{ marginRight: 16 }}
					/>
				</div>
				<div className="col-2">
					<FormControlLabel
						control={
							<Switch
								checked={isAlert}
								onChange={(e) => setIsAlert(e.target.checked)}
							/>
						}
						label="Show Alerts Thresholds"
					/>
				</div>
			</div>

			{logs && logs.length > 0 ? (
				<>
					<TableContainer component={Paper} className="mt-2">
						<Table className="table-sm">
							<TableHead>
								<TableRow>
									<TableCell>Title</TableCell>
									<TableCell>Nick Name</TableCell>
									<TableCell>Group Name</TableCell>
									<TableCell>Warning Threshold</TableCell>
									<TableCell>Alert Threshold</TableCell>
									<TableCell>Emails</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className="p-4">
								{filteredData.map((log) => (
									<TableRow key={log._id}>
										<TableCell>{log.title}</TableCell>
										<TableCell>
											{log.endpoint.nickname}
										</TableCell>
										<TableCell>
											{log.endpoint.groupName}
										</TableCell>
										<TableCell>
											<span
												style={{
													color: log.title
														.toLowerCase()
														.includes("warning")
														? "orange"
														: "inherit",
												}}
											>
												{log.endpoint.warningThreshold}{" "}
												ms
											</span>
										</TableCell>
										<TableCell>
											<span
												style={{
													color: log.title
														.toLowerCase()
														.includes("alert")
														? "red"
														: "inherit",
												}}
											>
												{log.endpoint.alertThreshold} ms
											</span>
										</TableCell>
										<TableCell>
											{log.emails.map((email, idx) => (
												<div key={idx}>
													{idx + 1}. {email}
												</div>
											))}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<div className="mt-1">
						<TablePagination
							component="div"
							count={count}
							page={page}
							onPageChange={handlePageChange}
							rowsPerPage={limit}
							onRowsPerPageChange={handleLimitChange}
							rowsPerPageOptions={[10, 20, 30, 50]}
						/>
					</div>
				</>
			) : (
				<span>
					<i>No data found</i>
				</span>
			)}
		</Container>
	);
};

export default AlertsHistory;
