import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Box, Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
	NotificationsNoneOutlined,
	ApiOutlined,
	GroupWorkOutlined,
	Groups2Outlined,
} from "@mui/icons-material";
import axios from "axios";
import useStore from "../store/store";
import { CustomTypography } from "../controls";
import Users from "../components/users";

function BasicCard({ color, number, icon, text }) {
	return (
		<Card sx={{ bgcolor: `${color}`, textAlign: "center" }}>
			<CardContent>
				<Grid container>
					<Grid item size={12}>
						<Box>{icon}</Box>
					</Grid>
					<Grid item size={12}>
						<CustomTypography text={number} />
					</Grid>
					<Grid item size={12}>
						<CustomTypography text={text} variant="h6" />
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

function Home() {
	let [stats, setStats] = useState({});
	const navigate = useNavigate();
	const { setIsLoading, setErrorMessage, user } = useStore((state) => state);

	useEffect(() => {
		if (!user) navigate("/login");

		fetchStats();
	}, []);

	const fetchStats = async () => {
		setIsLoading(true);
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/stats`
			);
			if (res.status === 200) {
				setStats(res.data);
				setIsLoading(false);
			}
		} catch (err) {
			console.error("Error fetching alerts:", err);
			setErrorMessage("Error fetching stats");
			setIsLoading(false);
		}
	};

	return (
		<Container>
			<CustomTypography text="Admin Dashboard" />
			<br />
			<CustomTypography text="Statistics" variant="h6" />

			<Grid container spacing={2} sx={{ p: 2 }}>
				<Grid item size={{ xs: 12, sm: 6, md: 3, lg: 3 }}>
					<BasicCard
						number={stats.alerts}
						icon={
							<NotificationsNoneOutlined
								color="primary"
								sx={{ fontSize: 60 }}
							/>
						}
						text="Alerts"
						color="#EEF0FF"
					/>
				</Grid>

				<Grid item size={{ xs: 12, sm: 6, md: 3, lg: 3 }}>
					<BasicCard
						number={stats.users}
						icon={
							<Groups2Outlined
								color="primary"
								sx={{ fontSize: 60 }}
							/>
						}
						text="Users"
						color="#EEF0FF"
					/>
				</Grid>

				<Grid item size={{ xs: 12, sm: 6, md: 3, lg: 3 }}>
					<BasicCard
						number={stats.endpoints}
						icon={
							<ApiOutlined
								color="primary"
								sx={{ fontSize: 60 }}
							/>
						}
						text="Endpoints"
						color="#EEF0FF"
					/>
				</Grid>

				<Grid item size={{ xs: 12, sm: 6, md: 3, lg: 3 }}>
					<BasicCard
						number={stats.groups}
						icon={
							<GroupWorkOutlined
								color="primary"
								sx={{ fontSize: 60 }}
							/>
						}
						text="Groups"
						color="#EEF0FF"
					/>
				</Grid>
			</Grid>

			{user.isAdmin && (
				<Grid container spacing={2} sx={{ mt: 3 }}>
					<Users />
				</Grid>
			)}
		</Container>
	);
}

export default Home;
