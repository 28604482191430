import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Container,
	Tooltip,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	styled,
	tooltipClasses,
	TablePagination,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import axios from "axios";
import useStore from "../store/store";
import {
	CustomButton,
	CustomTypography,
	CustomChip,
	CustomTextField,
} from "../controls";
import ConfirmDialog from "../components/confirmDialog";

const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
	},
}));

const APIEndpoints = () => {
	const navigate = useNavigate();
	const {
		setIsLoading,
		toEdit,
		setToEdit,
		setSuccessMessage,
		setInfoMessage,
		setErrorMessage,
		user,
	} = useStore((state) => state);
	let [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [endpoints, setEndpoints] = useState([]);
	const [query, setQuery] = useState("");
	const [filteredEndpoints, setFilteredEndpoints] = useState([]);
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);

	useEffect(() => {
		fetchEndpoints();
	}, []);

	const fetchEndpoints = async () => {
		setIsLoading(true);
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/api-endpoints?page=${page}&limit=${limit}`
			);
			if (res.status === 200) {
				setEndpoints(res.data.data);
				setCount(res.data.total);
				setIsLoading(false);
			}
		} catch (err) {
			console.error("Error fetching API endpoints:", err);
			setIsLoading(false);
		}
	};

	const handleEditEndpoint = async (endpoint) => {
		setToEdit(endpoint);
		navigate("/add-new-endpoint");
	};

	const handleDeleteEndpoint = async () => {
		setIsLoading(true);
		try {
			let res = await axios.delete(
				`${process.env.REACT_APP_API_URL}/api-endpoints/${toEdit._id}`
			);
			if (res.status === 200) {
				fetchEndpoints();
				setInfoMessage(
					"Endpoint & its testing history deleted successfully"
				);
				setToEdit(null);
				setIsLoading(false);
				setIsConfirmOpen(false);
			}
		} catch (err) {
			console.error("Error deleting endpoint:", err);
			setErrorMessage("Error deleting endpoint. Please try again.");
			setIsLoading(false);
			setIsConfirmOpen(false);
		}
	};

	const handleTestEndpoint = async (id) => {
		setIsLoading(true);
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/main-server/${id}`
			);

			// `${process.env.REACT_APP_API_URL}/api-endpoints/${id}/test`
			// `${process.env.REACT_APP_API_URL}/main-server/${id}`

			if (res.data.status === 200) setSuccessMessage(res.data.msg);
			else setInfoMessage(res.data.msg);

			setIsLoading(false);
		} catch (err) {
			console.error("Error testing API endpoint:", err);
			setErrorMessage(
				"Request failed on one or more servers. Please try again."
			);
			setIsLoading(false);
		}
		fetchEndpoints();
	};

	useEffect(() => {
		if (endpoints.length === 0) return;

		setFilteredEndpoints(
			endpoints.filter(
				(endpoint) =>
					endpoint.nickname
						.toLowerCase()
						.includes(query.toLowerCase()) ||
					endpoint.groupName
						.toLowerCase()
						.includes(query.toLowerCase())
			)
		);
	}, [query, endpoints]);

	useEffect(() => {
		fetchEndpoints(page, limit);
	}, [page, limit]);

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	const handleLimitChange = (event) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<>
			<Container>
				<CustomTypography text="API Endpoints" />

				{user.isAdmin && (
					<div style={{ textAlign: "right" }}>
						<CustomButton
							onClick={() => {
								setToEdit(null);
								navigate("/add-new-endpoint");
							}}
							text="Add API Endpoint"
							mx="0px"
							icon={<AddOutlined />}
						/>
					</div>
				)}

				<CustomTextField
					label={`Filter by nick name or group name`}
					value={query}
					onChange={(e) => setQuery(e.target.value)}
				/>

				{endpoints && endpoints.length > 0 ? (
					<>
						<TableContainer component={Paper} className="mt-2">
							<Table className="table-sm">
								<TableHead>
									<TableRow>
										<TableCell>Nickname</TableCell>
										<TableCell>Group</TableCell>
										<TableCell>URL</TableCell>
										<TableCell>HTTP Method</TableCell>
										<TableCell>Path Parameters</TableCell>
										<TableCell>Query Parameters</TableCell>
										<TableCell>Authentication</TableCell>
										<TableCell>Data Points</TableCell>
										<TableCell>Test Frequency</TableCell>
										<TableCell>Warning Threshold</TableCell>
										<TableCell>Alert Threshold</TableCell>
										<TableCell>Status</TableCell>
										<TableCell>Response Status</TableCell>
										<TableCell>Response Time</TableCell>
										<TableCell align="center">
											Actions
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredEndpoints.map((endpoint) => (
										<TableRow key={endpoint._id}>
											<TableCell>
												{endpoint.nickname}
											</TableCell>
											<TableCell>
												{endpoint.groupName}
											</TableCell>
											<TableCell>
												{endpoint.url}
											</TableCell>
											<TableCell>
												{endpoint.method}
											</TableCell>
											<TableCell>
												{endpoint.pathParameters || "-"}
											</TableCell>
											<TableCell>
												{endpoint.queryParameters ||
													"-"}
											</TableCell>
											<TableCell
												className="text-truncate"
												style={{
													maxWidth: "400px",
													overflow: "hidden",
													cursor: "pointer",
												}}
											>
												<BootstrapTooltip
													title={
														endpoint.authentication
													}
												>
													<span
														className="text-truncate"
														style={{
															display: "block",
														}}
													>
														{endpoint.authentication ||
															"-"}
													</span>
												</BootstrapTooltip>
											</TableCell>
											<TableCell>
												{endpoint.dataPoints || "-"}
											</TableCell>
											<TableCell>
												{endpoint.testFrequency || 0}{" "}
												min
											</TableCell>
											<TableCell>
												{endpoint.warningThreshold || 0}{" "}
												ms
											</TableCell>
											<TableCell>
												{endpoint.alertThreshold || 0}{" "}
												ms
											</TableCell>
											<TableCell>
												{endpoint.status === 200 ? (
													<span
														style={{
															color: "green",
														}}
													>
														Active
													</span>
												) : (
													<span
														style={{ color: "red" }}
													>
														Inactive
													</span>
												)}
											</TableCell>
											<TableCell>
												{endpoint.status === 200 ? (
													<CustomChip
														label={endpoint.status}
														color="success"
													/>
												) : endpoint.status === 500 ? (
													<CustomChip
														label={endpoint.status}
														color="error"
													/>
												) : endpoint.status === 0 ? (
													<CustomChip label="N/A" />
												) : (
													<CustomChip
														label={endpoint.status}
														color="warning"
													/>
												)}
											</TableCell>
											<TableCell>
												{endpoint.lastResponseTime
													? `${endpoint.lastResponseTime} ms`
													: "N/A"}
											</TableCell>
											<TableCell
												sx={{ minWidth: 290 }}
												align="center"
											>
												<CustomButton
													text="Test"
													color="secondary"
													onClick={() =>
														handleTestEndpoint(
															endpoint._id
														)
													}
												/>
												{user.isAdmin && (
													<CustomButton
														text="Edit"
														onClick={() =>
															handleEditEndpoint(
																endpoint
															)
														}
													/>
												)}
												{user.isAdmin && (
													<CustomButton
														text="Delete"
														color="error"
														onClick={() => {
															setIsConfirmOpen(
																true
															);
															setToEdit(endpoint);
														}}
													/>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						<TablePagination
							component="div"
							count={count}
							page={page}
							onPageChange={handlePageChange}
							rowsPerPage={limit}
							onRowsPerPageChange={handleLimitChange}
							rowsPerPageOptions={[10, 20, 30, 50]}
						/>
					</>
				) : (
					<span>
						<i>No data found</i>
					</span>
				)}
			</Container>

			{isConfirmOpen && (
				<ConfirmDialog
					isConfirmOpen={isConfirmOpen}
					title="Are you sure?"
					body="Are you sure you want to delete this record? This will delete the endpoint along with its testing history. This action can't be undone!"
					onNo={() => setIsConfirmOpen(false)}
					onYes={() => handleDeleteEndpoint()}
				/>
			)}
		</>
	);
};

export default APIEndpoints;
