import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Container,
	Switch,
	FormControlLabel,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import axios from "axios";
import useStore from "../store/store";
import {
	CustomTextField,
	CustomTypography,
	CustomChip,
	CustomButton,
} from "../controls";
import Chart from "../components/chart";

const TestingHistory = () => {
	const navigate = useNavigate();
	const [logs, setLogs] = useState([]);
	const [query, setQuery] = useState("");
	const [isActive, setIsActive] = useState(false);
	const [isInactive, setIsInactive] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [data, setData] = useState([]);
	const { setIsLoading } = useStore((state) => state);
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);

	useEffect(() => {
		fetchLogs();
	}, []);

	const fetchLogs = async (page, limit) => {
		setIsLoading(true);
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/testing-history?page=${page}&limit=${limit}`
			);
			if (res.status === 200) {
				setLogs(res.data.data);
				setCount(res.data.total);
				setIsLoading(false);
				manageData(res.data.data);
			}
		} catch (err) {
			console.error("Error fetching API logs:", err);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (logs.length === 0) return;
		let filteredLogs = logs;

		filteredLogs = filteredLogs.filter(
			(log) =>
				log.endpoint.groupName
					.toLowerCase()
					.includes(query.toLowerCase()) ||
				log.endpoint.nickname
					.toLowerCase()
					.includes(query.toLowerCase())
		);

		if (isActive && isInactive) {
			setFilteredData(filteredLogs);
			return;
		}

		if (isActive)
			filteredLogs = filteredLogs.filter((log) => log.status === 200);
		if (isInactive)
			filteredLogs = filteredLogs.filter((log) => log.status !== 200);

		setFilteredData(filteredLogs);
	}, [isActive, isInactive, query, logs]);

	useEffect(() => {
		fetchLogs(page, limit);
	}, [page, limit]);

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	const handleLimitChange = (event) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(0);
	};

	const manageData = (logs) => {
		let k = [];
		logs.map((log) => {
			return k.push({
				status: log.status,
				nickname: log.endpoint.nickname,
				lastResponseTime: log.responseTime,
			});
		});
		setData(k);
	};

	return (
		<Container className="mb-4" maxWidth="lg">
			<CustomTypography text="Testing History" />

			<div className="row">
				<div className="col-8">
					<CustomTextField
						label={`Filter by nick name or group name`}
						value={query}
						onChange={(e) => setQuery(e.target.value)}
						required={false}
					/>
				</div>
				<div className="col-2 pt-2">
					<FormControlLabel
						control={
							<Switch
								checked={isActive}
								onChange={(e) => setIsActive(e.target.checked)}
							/>
						}
						label="Show Active"
						style={{ marginRight: 16 }}
					/>
				</div>
				<div className="col-2 pt-2">
					<FormControlLabel
						control={
							<Switch
								checked={isInactive}
								onChange={(e) =>
									setIsInactive(e.target.checked)
								}
							/>
						}
						label="Show Inactive"
					/>
				</div>
			</div>

			{filteredData && filteredData.length > 0 ? (
				<>
					<TableContainer component={Paper} className="mt-2">
						<Table className="table-sm">
							<TableHead>
								<TableRow>
									<TableCell>Nick Name</TableCell>
									<TableCell>Group Name</TableCell>
									<TableCell>Server</TableCell>
									<TableCell>Active</TableCell>
									<TableCell>Status</TableCell>
									<TableCell>Response Time</TableCell>
									<TableCell align="center">Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredData.map((log) => (
									<TableRow key={log._id}>
										<TableCell>
											{log.endpoint.nickname}
										</TableCell>
										<TableCell>
											{log.endpoint.groupName}
										</TableCell>
										<TableCell>
											{log.server
												? log.server.location
												: "-"}
										</TableCell>
										<TableCell>
											{log.status === 200 ? (
												<CustomChip
													label="Active"
													color="success"
												/>
											) : (
												<CustomChip
													label="Inactive"
													color="error"
												/>
											)}
										</TableCell>
										<TableCell>
											{log.status === 200 ? (
												<CustomChip
													label={log.status}
													color="success"
												/>
											) : log.status === 500 ? (
												<CustomChip
													label={log.status}
													color="error"
												/>
											) : log.status === 0 ? (
												"N/A"
											) : (
												<CustomChip
													label={log.status}
													color="warning"
												/>
											)}
										</TableCell>
										<TableCell>
											{log.responseTime
												? `${log.responseTime} ms`
												: "N/A"}
										</TableCell>
										<TableCell align="center">
											<CustomButton
												text="Details"
												color="secondary"
												onClick={() =>
													navigate(
														`/test-details?id=${log._id}`
													)
												}
												icon={<InfoOutlined />}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<div className="mt-1">
						<TablePagination
							component="div"
							count={count}
							page={page}
							onPageChange={handlePageChange}
							rowsPerPage={limit}
							onRowsPerPageChange={handleLimitChange}
							rowsPerPageOptions={[10, 20, 30, 50]}
						/>
					</div>
				</>
			) : (
				<span>
					<i>No data found</i>
				</span>
			)}

			{data && data.length > 0 && <Chart data={data} />}
		</Container>
	);
};

export default TestingHistory;
